import React, {
  FC,
  SetStateAction,
  useState,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { Box } from '@mui/material';
import {
  UserSettingContext,
  UserSettingSource,
  useUserSettings,
} from '@bestseller-bit/sales-and-invoicing.hooks.use-user-settings';
import { BESTONE_AUTH_GET_USER_INFO_PENDING_DONE } from '../../../constants/shared/bestone.auth.constants';
import { SHOW_ALL_COUNTRIES } from '../../../constants/shared/bestone.global.constants';
import { SHOW_ALL_CURRENCIES } from '../../../constants/shared/bestone.myProfile.constrants';
import { AuthorizationError } from '../../../utility/Authorization';
import { getAllCurrencies } from './__generated__/getAllCurrencies';
import { GetCurrentUser } from './__generated__/GetCurrentUser';
import { getAllActiveCountries_findByCountryColumns } from './__generated__/getAllActiveCountries';

export type UserContextType = {
  currency: string | undefined;
  setCurrency: React.Dispatch<SetStateAction<string | undefined>>;
  marketPublicId: string | undefined;
  setMarketPublicId: React.Dispatch<SetStateAction<string | undefined>>;
  localityPublicId: string | undefined;
  setLocalityPublicId: React.Dispatch<SetStateAction<string | undefined>>;
};

export const UserContext = createContext<UserContextType>({
  currency: 'EUR',
  setCurrency: () => {},
  marketPublicId: '',
  setMarketPublicId: () => {},
  localityPublicId: '',
  setLocalityPublicId: () => {},
});

const LoadingComponent: FC = () => {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Loading message="Loading user" />
    </Box>
  );
};

type CurrentUserContainerProps = {
  children: React.ReactElement;
};

const CurrentUserContainer: FC<CurrentUserContainerProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { addErrorMessage } = useFeedbackContext();

  const { userSettings } = useUserSettings(UserSettingContext.myprofile, [
    UserSettingSource.currency,
    UserSettingSource.locality,
    UserSettingSource.market,
  ]);

  useEffect(() => {
    if (userSettings) {
      userSettings.forEach(
        (setting: {
          source: UserSettingSource;
          value: React.SetStateAction<string | undefined>;
        }) => {
          if (setting.source === UserSettingSource.currency) {
            setCurrency(setting.value ? setting.value : 'EUR');
          } else if (setting.source === UserSettingSource.locality) {
            setLocalityPublicId(setting.value);
          } else if (setting.source === UserSettingSource.market) {
            setMarketPublicId(setting.value);
          }
        }
      );
    }
  }, [userSettings]);

  const { data: currentUser, error } = useQuery<GetCurrentUser>(
    GetCurrentUserQuery,
    {
      fetchPolicy: 'network-only',
      onError: (queryError) => {
        addErrorMessage(queryError.message, GetCurrentUserQuery);
      },
    }
  );

  useQuery<getAllActiveCountries_findByCountryColumns>(
    getAllActiveCountriesQuery,
    {
      context: { clientName: 'shared' },
      fetchPolicy: 'cache-first',
      variables: { column: 'ACTIVESALE' },
      onCompleted: (queryData) => {
        dispatch({ type: SHOW_ALL_COUNTRIES, payload: queryData });
      },
      onError: (queryError) => {
        addErrorMessage(queryError.message, getAllActiveCountriesQuery);
      },
    }
  );
  useQuery<getAllCurrencies>(getAllCurrenciesQuery, {
    context: { clientName: 'shared' },
    fetchPolicy: 'cache-first',
    onCompleted: (queryData) => {
      dispatch({ type: SHOW_ALL_CURRENCIES, payload: queryData });
    },
    onError: (queryError) => {
      addErrorMessage(queryError.message, getAllCurrenciesQuery);
    },
  });

  useEffect(() => {
    const updateState = () => {
      if (currentUser) {
        dispatch({
          type: BESTONE_AUTH_GET_USER_INFO_PENDING_DONE,
          payload: currentUser,
        });
      } else {
        dispatch({
          type: BESTONE_AUTH_GET_USER_INFO_PENDING_DONE,
          payload: null,
        });
      }
    };
    updateState();
  }, [currentUser, dispatch]);

  const [currency, setCurrency] = useState<string | undefined>('EUR');
  const [marketPublicId, setMarketPublicId] = useState<string | undefined>();
  const [localityPublicId, setLocalityPublicId] = useState<
    string | undefined
  >();

  const contextValue = useMemo(() => {
    return {
      currency,
      setCurrency,
      localityPublicId,
      setLocalityPublicId,
      marketPublicId,
      setMarketPublicId,
    };
  }, [currency, localityPublicId, marketPublicId]);

  // TODO: Remove currency from LocalStorage when the services/API's are completely removed
  useEffect(() => {
    if (currency) {
      localStorage.setItem('currency', currency);
    }
  }, [currency]);

  if (error) {
    return <AuthorizationError />;
  }

  if (!currentUser) {
    return <LoadingComponent />;
  }

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default CurrentUserContainer;

const GetCurrentUserQuery = gql`
  query GetCurrentUser {
    currentUser {
      userName
      sourceId
      sourceDb
      sourceTable
      sourceAd
      environment
      queryUserId
      queryUserName
      roles
      internalUser
      queryUserMail
    }
  }
`;
const getAllActiveCountriesQuery = gql`
  query getAllActiveCountries($column: CountryColumns!) {
    findByCountryColumns(column: $column) {
      queryCount
      result {
        publicId
        name
        countryIsoAlpha3
        intAccessCode
        activeSale
        priceListCountry
      }
    }
  }
`;

const getAllCurrenciesQuery = gql`
  query getAllCurrencies {
    getCurrencies {
      queryCount
      result {
        currencyCode
        description
      }
    }
  }
`;
